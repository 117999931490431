/*
 * @Author: qxy
 * @Date: 2019-08-12 19:30:23
 * @Last Modified by: qxy
 * @Last Modified time: 2019-08-12 19:35:06
 */
/* eslint-disable max-len */

export default {
    name: 'fast',
    theme: 'outline',
    icon: {
        tag: 'svg',
        attrs: {
            viewBox: '0 0 114 80'
        },
        children: [
            {
                tag: 'path',
                attrs: {
                    d: 'M23.615 14.722V11.85h2.871v2.872h-2.871zm2.871 2.871v-2.87h2.872v2.87h-2.872zm-2.871 2.873v-2.872h2.871v2.872h-2.871zm-2.872-2.873v-2.87h2.872v2.87h-2.872zm-2.871 43.01V57.73h2.871v2.872h-2.871zm2.871 2.871v-2.872h2.872v2.872h-2.872zm-2.871 2.872v-2.87h2.871v2.87h-2.871zM15 63.476v-2.873h2.872v2.872H15zm83.02-15.127v-2.87h2.871v2.87H98.02zm2.871 2.872V48.35h2.872v2.872h-2.872zm-2.872 2.872v-2.87h2.871v2.87h-2.871zm-35.68 9.27v-2.65c8.4 0 15.235-6.834 15.235-15.235h2.65c0 9.862-8.023 17.885-17.885 17.885zm0-14.266a3.622 3.622 0 0 0 3.619-3.618 3.622 3.622 0 0 0-3.62-3.62 3.622 3.622 0 0 0-3.617 3.62 3.622 3.622 0 0 0 3.618 3.618zm3.392-8.883l7.608-7.608 1.873 1.873-7.608 7.608a6.235 6.235 0 0 1 1.005 3.392 6.276 6.276 0 0 1-6.27 6.268 6.276 6.276 0 0 1-6.27-6.268 6.277 6.277 0 0 1 6.27-6.27c1.25 0 2.413.372 3.392 1.005zM50.369 57.462v2.65h-19v-2.65h8.734a25.108 25.108 0 0 1-2.229-5.652l2.566-.662a22.394 22.394 0 0 0 2.74 6.314h7.19zm7.376-42.629h10.166V11.65H56.767v3.183h.978zm26.653 10.459l-3.317 3.317c4.057 4.51 6.523 10.447 6.523 16.87 0 13.93-11.334 25.264-25.265 25.264H38.4v-2.65h12.68a25.336 25.336 0 0 1-3.676-2.236l1.57-2.136a22.436 22.436 0 0 0 13.366 4.372c12.47 0 22.615-10.145 22.615-22.614 0-11.25-8.395-20.879-19.526-22.4l-1.146-.156v-5.44h-3.887v5.44l-1.145.156a22.614 22.614 0 0 0-14.145 7.766h5.264v2.65h-19v-2.65h10.384a25.324 25.324 0 0 1 9.346-7.982H38.4v-2.65h19.346v-2.73h-3.628V9h16.444v8.483h-3.628v3.155c4.694.863 8.896 3.041 12.278 6.094l3.314-3.314-1.264-1.264 1.873-1.873 4.43 4.43-1.874 1.873-1.292-1.292zm-39.75 18.861v2.65H20.742v-2.65h16.382a25.197 25.197 0 0 1 1.15-6.39l2.522.81a22.547 22.547 0 0 0-1.022 5.58h4.872zm50.499 7.07V48.35h2.872v2.872h-2.872z'
                }
            }
        ]
    }
};
