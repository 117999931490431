/*
 * @Author: zhaoyang
 * @Date: 2022-09-07 14:23:37
 * @Last Modified by: wuhuiwang
 * @Last Modified time: 2024-01-19 14:19:29
 */

import StageType from 'ssr-common/constant/stage-type';

export default Vue => ({
    async mounted() {
        if ([StageType.dev, StageType.testOverseas, StageType.testIndo, StageType.testMex, StageType.testSea].includes(__STAGE__)) { // eslint-disable-line no-undef
            const {default: VConsole} = await import('vconsole');
            Vue.use(new VConsole());
        }
    }
});
