/*
 * @Author: qxy
 * @Date: 2019-09-10 15:29:57
 * @Last Modified by: qxy
 * @Last Modified time: 2019-09-10 16:08:27
 */

/* eslint-disable max-len */

export default {
    name: 'icon-arrow',
    theme: 'outline',
    icon: {
        tag: 'svg',
        attrs: {
            viewBox: '0 0 12 12'
        },
        children: [
            {
                tag: 'path',
                attrs: {
                    d: 'M4.4 0L3 1.4 7.6 6 3 10.6 4.4 12l6-6z'
                }
            }
        ]
    }
};
