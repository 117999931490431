/*
 * @Author: qxy
 * @Date: 2019-08-09 19:09:23
 * @Last Modified by: qxy
 * @Last Modified time: 2019-08-12 17:41:50
 */

/* eslint-disable max-len */

export default {
    name: 'quote',
    theme: 'outline',
    icon: {
        tag: 'svg',
        attrs: {viewBox: '0 0 120 40'},
        children: [
            {
                tag: 'path',
                attrs: {
                    d: 'M67.467 32.37L60 40l-7.467-7.63H8a8 8 0 0 1-8-8V8a8 8 0 0 1 8-8h104a8 8 0 0 1 8 8v16.37a8 8 0 0 1-8 8H67.467z'
                }
            }
        ]
    }
};
