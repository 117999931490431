var Brand = {
    'investaja': 'IDN',
    'easycash': 'IDN',
    'credmex': 'MEX',
    'mabilis-cash': 'PHI',
    'easypln': 'POL',
    'th-fincash': 'THA',
    'mayacash': 'MEX'
};
export default Brand;
