<!-- @author chengyuzhang -->
<!-- @email chengyuzhang@yangqianguang.com -->
<!-- @date 2020-04-30 17:20:56.295 -->
<!-- @desc generated by yqg-cli@1.2.8-alpha.9 -->

<template>
    <div
        class="ck-header"
        :class="headerClass"
    >
        <div class="container">
            <div class="logo">
                <router-link
                    :to="{name: 'Index'}"
                >
                    <img
                        class="logo-icon"
                        src="./img/logo.svg"
                        alt=""
                    >
                </router-link>
            </div>
            <div class="btn-group">
                <div
                    :class="{active: routerName === 'Borrow'}"
                    class="btn"
                >
                    <router-link
                        :to="{name: 'Borrow'}"
                        tag="button"
                    >
                        {{ $t('borrow.title') }}
                    </router-link>
                    <div class="indicator" />
                </div>
                <div
                    :class="{active: routerName === 'Faq'}"
                    class="btn"
                >
                    <router-link
                        :to="{name: 'Faq'}"
                        tag="button"
                    >
                        {{ $t('faq.title') }}
                    </router-link>
                    <div class="indicator" />
                </div>
            </div>
        </div>
    </div>
</template>

<script type="text/babel">

export default {
    name: 'CkHeader',

    data() {
        return {
            scrollTop: 0
        };
    },

    computed: {
        headerClass() {
            const classObj = {
                'background-gray': !this.scrollTop && this.routerName === 'Borrow'
            };

            return classObj;
        },
        routerName() {
            return this.$route.name;
        }
    },

    mounted() {
        window.onscroll = () => {
            this.scrollTop = document.documentElement.scrollTop || window.pageYOffset || window.pageYOffset;
        };
    }
};

</script>

<style lang="scss" rel="stylesheet/scss" scoped>
@import "~olacred/common/style/olacred";

.ck-header {
    position: sticky;
    top: 0;
    z-index: 6;
    padding: 20px 0;
    background: #fff;
    box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 15%);

    @media screen and (max-width: $screen-md) {
        padding: 17px 30px;
    }

    .container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0;

        .logo {
            .logo-icon {
                width: 120px;

                @media screen and (max-width: $screen-md) {
                    width: 80px;
                }
            }
        }

        .btn-group {
            display: flex;

            .btn {
                position: relative;

                .indicator {
                    margin-top: 4px;
                    display: block;
                    position: absolute;
                    left: 50%;
                    transform: translateX(-50%);
                    width: 100%;
                    height: 4px;
                    border-radius: 2px;
                    opacity: 0%;
                    background-color: #ff734b;
                    transition: 0.5s ease-in-out all;
                }

                &:first-child {
                    margin-right: 77px;

                    @media screen and (max-width: $screen-md) {
                        margin-right: 0;
                    }
                }

                button {
                    position: relative;
                    border: none;
                    font-family: Helvetica, sans-serif;
                    font-size: 16px;
                    line-height: 22px;
                    border-radius: 23px;
                    outline: 0;
                    background-color: inherit;
                    color: $light-black;

                    @media screen and (max-width: $screen-md) {
                        padding: 4px 6px;
                        font-size: 12px;
                        line-height: 18px;
                    }
                }

                &.active {
                    button {
                        font-family: Helvetica-Bold, Helvetica;
                        font-weight: bold;
                    }

                    .indicator {
                        opacity: 100%;
                    }
                }
            }
        }
    }
}
</style>
