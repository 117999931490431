/*
 * @Author: qxy
 * @Date: 2019-08-01 15:10:45
 * @Last Modified by: qxy
 * @Last Modified time: 2019-08-09 19:10:12
 */

/* eslint-disable max-len */

export default {
    name: 'about-us',
    theme: 'outline',
    icon: {
        tag: 'svg',
        attrs: {viewBox: '0 0 48 48', x: '0', y: '0'},
        children: [
            {
                tag: 'path',
                attrs: {
                    d: 'M32,37l-10-4.4L12,37V14.9h20V37z M22,30.5l8,3.5V16.9H14V34L22,30.5z'
                }
            },
            {
                tag: 'polygon',
                attrs: {
                    points: '36,31.6 34,31.6 34,13 17,13 17,11 36,11'
                }
            },
            {
                tag: 'rect',
                attrs: {
                    x: '17',
                    y: '24.7',
                    width: '10',
                    height: '2'
                }
            },
            {
                tag: 'rect',
                attrs: {
                    x: '17',
                    y: '20.8',
                    width: '10',
                    height: '2'
                }
            }
        ]
    }
};
