var directive = {
    inserted: function (el, _a) {
        var _b = _a.value, value = _b === void 0 ? {} : _b;
        var _c = value.color, color = _c === void 0 ? 'rgba(0, 0, 0, .05)' : _c, _d = value.duration, duration = _d === void 0 ? 0.4 : _d, _e = value.radius, radius = _e === void 0 ? 1.5 : _e, _f = value.disabled, disabled = _f === void 0 ? false : _f;
        var exist = false;
        el.rippleDisabled = disabled;
        el.addEventListener('click', function (_a) {
            var offsetX = _a.offsetX, offsetY = _a.offsetY;
            if (exist || el.rippleDisabled)
                return;
            var btnWidth = el.offsetWidth, btnHeight = el.offsetHeight;
            var rippleRadius = Math.max(btnWidth, btnHeight);
            var ripple = document.createElement('div');
            ripple.style.background = color;
            ripple.style.transition = "all ".concat(duration, "s ease");
            ripple.style.position = 'absolute';
            ripple.style.borderRadius = '50%';
            ripple.style.width = '0';
            ripple.style.height = '0';
            ripple.style.top = "".concat(offsetY, "px");
            ripple.style.left = "".concat(offsetX, "px");
            ripple.style.transform = 'translate(-50%, -50%)';
            if (window.getComputedStyle(el).position === 'static') {
                el.style.position = 'relative';
            }
            el.style.overflow = 'hidden';
            el.appendChild(ripple);
            exist = true;
            setTimeout(function () {
                var realRadius = rippleRadius * radius;
                ripple.style.width = "".concat(realRadius, "px");
                ripple.style.height = "".concat(realRadius, "px");
            }, 0);
            setTimeout(function () {
                if (!exist)
                    return;
                el.removeChild(ripple);
                exist = false;
            }, (duration * 1000));
        });
    },
    update: function (el, _a) {
        var _b = _a.value, _c = _b === void 0 ? {} : _b, disabled = _c.disabled;
        setTimeout(function () {
            el.rippleDisabled = disabled;
        }, 100);
    }
};
export default directive;
