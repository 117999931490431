/**
 * @Author: xiaoyaqu
 * @Date: 2019-05-28 15:38:53.379
 * @Last Modified by: ruiwang
 * @Last Modified time: 2023-03-29 下午03:49:31
 */
import AntdIcon from './antd-icon';

export default {

    install(Vue) {
        Vue.use(AntdIcon);
    }

};
