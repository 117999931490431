var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "ck-header", class: _vm.headerClass }, [
    _c("div", { staticClass: "container" }, [
      _c(
        "div",
        { staticClass: "logo" },
        [
          _c("router-link", { attrs: { to: { name: "Index" } } }, [
            _c("img", {
              staticClass: "logo-icon",
              attrs: { src: require("./img/logo.svg"), alt: "" },
            }),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "btn-group" }, [
        _c(
          "div",
          {
            staticClass: "btn",
            class: { active: _vm.routerName === "Borrow" },
          },
          [
            _c(
              "router-link",
              { attrs: { to: { name: "Borrow" }, tag: "button" } },
              [
                _vm._v(
                  "\n                    " +
                    _vm._s(_vm.$t("borrow.title")) +
                    "\n                "
                ),
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "indicator" }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "btn", class: { active: _vm.routerName === "Faq" } },
          [
            _c(
              "router-link",
              { attrs: { to: { name: "Faq" }, tag: "button" } },
              [
                _vm._v(
                  "\n                    " +
                    _vm._s(_vm.$t("faq.title")) +
                    "\n                "
                ),
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "indicator" }),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }