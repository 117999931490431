/*
 * @Author: zhanghchengyu
 * @Date: 2020-04-02 18:15:00
 * @Last Modified by: zhanghchengyu
 * @Last Modified time: 2020-04-02 18:15:00
 */
/* eslint-disable max-len */

export default {
    name: 'hide-password',
    theme: 'outline',
    icon: {
        tag: 'svg',
        attrs: {
            viewBox: '0 0 1024 1024'
        },
        children: [
            {
                tag: 'path',
                attrs: {
                    d: 'M393.066667 387.2l49.066666 49.066667 150.933334 150.933333 49.066666 49.066667 92.8 92.8 64 64c13.333333 13.333333 13.333333 35.733333 0 49.066666s-35.733333 13.333333-49.066666 0l-75.2-75.2c-54.4 28.8-108.8 43.733333-163.2 43.733334-142.4 0-284.266667-99.733333-426.666667-298.666667 52.266667-73.066667 104-132.266667 156.266667-178.666667L180.8 273.066667c-13.333333-13.333333-13.333333-35.733333 0-49.066667 13.333333-13.333333 35.733333-13.333333 49.066667 0l66.133333 66.133333 97.066667 97.066667z m-40 57.6c-9.066667 21.333333-14.4 44.8-14.4 69.866667 0 97.066667 78.933333 176 176 176 24.533333 0 48-5.333333 69.866666-14.4l-56-56c-4.266667 0.533333-9.066667 1.066667-13.866666 1.066666-58.666667 0-106.666667-48-106.666667-106.666666 0-4.8 0.533333-9.066667 1.066667-13.866667L353.066667 444.8z m-5.866667-186.666667C402.133333 228.266667 457.066667 213.333333 512 213.333333c142.4 0 284.266667 99.733333 426.666667 298.666667-52.8 73.6-105.066667 133.333333-157.866667 179.733333l-105.6-105.6c9.6-21.866667 15.466667-46.4 15.466667-71.466666 0-97.066667-78.933333-176-176-176-25.6 0-49.6 5.333333-71.466667 15.466666l-96-96z m150.933333 150.933334c5.333333-1.066667 10.666667-1.066667 16.533334-1.066667 58.666667 0 106.666667 48 106.666666 106.666667 0 5.333333-0.533333 11.2-1.066666 16.533333L498.133333 409.066667z'
                }
            }
        ]
    }
};
