/**
 * @Author: chengyuzhang
 * @Date: 2020-04-13 16:38:27.247
 * @Last Modified by: ruiwang
 * @Last Modified time: 2023-04-07 16:04:03
 */

import CkFooter from './ck-footer';
import CkHeader from './ck-header';

export default {

    install(Vue) {
        Vue.component('ck-header', CkHeader);
        Vue.component('ck-footer', CkFooter);
    }

};
