/*
 * @Author: qxy
 * @Date: 2019-09-10 15:29:57
 * @Last Modified by: qxy
 * @Last Modified time: 2019-09-10 15:34:55
 */

/* eslint-disable max-len */

export default {
    name: 'icon-checked',
    theme: 'outline',
    icon: {
        tag: 'svg',
        attrs: {
            viewBox: '0 0 12 12'
        },
        children: [
            {
                tag: 'path',
                attrs: {
                    d: 'M9.306 5.094L6 8.312a.677.677 0 0 1-.935 0L3.193 6.5a.628.628 0 0 1 0-.906.677.677 0 0 1 .936 0l1.402 1.359 2.84-2.765a.677.677 0 0 1 .935 0 .627.627 0 0 1 0 .906M6 0a6 6 0 1 0 0 12A6 6 0 0 0 6 0'
                }
            }
        ]
    }
};
