/*
 * @Author: qxy
 * @Date: 2019-08-13 16:31:49
 * @Last Modified by: qxy
 * @Last Modified time: 2019-08-13 16:34:00
 */
/* eslint-disable max-len */

export default {
    name: 'arrow-right',
    theme: 'outline',
    icon: {
        tag: 'svg',
        attrs: {
            viewBox: '0 0 138 15'
        },
        children: [
            {
                tag: 'path',
                attrs: {
                    d: 'M1.525 5.573a1.5 1.5 0 1 1 0 3C.696 8.573 0 7.902 0 7.073s.647-1.5 1.475-1.5h.05zm43.334 0a1.5 1.5 0 1 1 0 3h-.102a1.5 1.5 0 1 1 0-3h.102zm-6.19 0a1.5 1.5 0 1 1 0 3h-.102a1.5 1.5 0 1 1 0-3h.102zm24.762 0a1.5 1.5 0 1 1 0 3h-.102a1.5 1.5 0 1 1 0-3h.102zm-12.381 0a1.5 1.5 0 1 1 0 3h-.102a1.5 1.5 0 1 1 0-3h.102zm6.19 0a1.5 1.5 0 1 1 0 3h-.102a1.5 1.5 0 1 1 0-3h.102zm-49.524 0a1.5 1.5 0 1 1 0 3h-.102a1.5 1.5 0 1 1 0-3h.102zm24.762 0a1.5 1.5 0 1 1 0 3h-.102a1.5 1.5 0 1 1 0-3h.102zm-18.572 0a1.5 1.5 0 1 1 0 3h-.102a1.5 1.5 0 1 1 0-3h.102zm6.19 0a1.5 1.5 0 1 1 0 3h-.101a1.5 1.5 0 1 1 0-3h.102zm6.192 0a1.5 1.5 0 1 1 0 3h-.102a1.5 1.5 0 1 1 0-3h.102zm55.715 0a1.5 1.5 0 1 1 0 3H81.9a1.5 1.5 0 1 1 0-3h.103zm37.143 0a1.5 1.5 0 1 1 0 3h-.102a1.5 1.5 0 1 1 0-3h.102zm-6.191 0a1.5 1.5 0 1 1 0 3h-.101a1.5 1.5 0 1 1 0-3h.1zm-6.19 0a1.5 1.5 0 1 1 0 3h-.103a1.5 1.5 0 1 1 0-3h.102zm18.572 0a1.5 1.5 0 1 1 0 3h-.102a1.5 1.5 0 1 1 0-3h.102zm-49.525 0a1.5 1.5 0 1 1 0 3h-.101a1.5 1.5 0 1 1 0-3h.1zm12.381 0a1.5 1.5 0 1 1 0 3h-.102a1.5 1.5 0 1 1 0-3h.102zm-18.572 0a1.5 1.5 0 1 1 0 3h-.102a1.5 1.5 0 1 1 0-3h.102zm24.762 0a1.5 1.5 0 1 1 0 3h-.102a1.5 1.5 0 1 1 0-3h.102zm6.19 0a1.5 1.5 0 1 1 0 3h-.101a1.5 1.5 0 1 1 0-3h.102zm36.286.086a2 2 0 0 1 0 2.828l-5.364 5.364c-.63.63-1.707.184-1.707-.707V1.002c0-.891 1.077-1.337 1.707-.707l5.364 5.364z'
                }
            }
        ]
    }
};
