<!-- @Author: chengyuzhang -->
<!-- @Date: 2020-12-01 10:53:37.224 -->
<!-- @Last Modified by: ruiwang -->
<!-- @Last Modified time: 2023-10-11 16:16:38 -->

<template>
    <div id="app">
        <router-view />
        <ec-webview-loading-circle v-show="!!reqCount" />
    </div>
</template>

<script type="babel/text">

import {genWebDeviceToken} from 'ssr-common/util/browser-util';
import ua from 'ssr-common/util/webview/ua-parsed';
import Vue from 'ssr-common/vue';
import interceptor from 'ssr-common/vue/mixin/interceptor';
import platformType from 'ssr-common/vue/mixin/platform-type';
import Vconsole from 'ssr-common/vue/mixin/vconsole';
import http from 'ssr-common/vue/vue-http';

import EcWebviewLoadingCircle from 'easycash/common/component/ec-webview-loading-circle';
import uploadEvent from 'easycash/common/mixin/upload-event';
import plugin from 'easycash/common/plugin';

import component from 'olacred/common/component';
import directive from 'olacred/common/directive';

const {common} = http.defaults.headers;
common.build = ua.versionCode || (!ua.isWebview && '28000') || '20000';
common['YQG-PLATFORM-SDK-TYPE'] = 'MEX_OLACRED';
common['YQG-PLATFORM-DEVICE-TOKEN'] = genWebDeviceToken();

Vue.use(component);
Vue.use(plugin);
Vue.use(directive);

export default {
    name: 'Olacred',

    components: {
        EcWebviewLoadingCircle
    },

    mixins: [Vconsole(Vue), interceptor, platformType, uploadEvent],

    created() {
        Vue.prototype.$app = this;
    }
};
</script>

<style lang="scss">
    @import "../common/style/index.scss";
</style>

