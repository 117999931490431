<!-- @author chengyuzhang -->
<!-- @email chengyuzhang@yangqianguang.com -->
<!-- @date 2020-04-30 13:51:05.279 -->
<!-- @desc generated by yqg-cli@1.2.8-alpha.9 -->

<template>
    <div
        v-reach-show="'elegant-in'"
        class="ck-footer"
    >
        <div class="contact-info">
            <div class="container">
                <div class="row">
                    <div class="col-md-6 item">
                        <h6>
                            {{ $t('footer.customerService') }}
                        </h6>
                        <div class="customer sub-body">
                            <div>
                                <a href="mailto:contacto@haab.mx">{{ $t('footer.email') }}</a>
                            </div>
                            <div>
                                <a href="tel:+5574545669">{{ $t('footer.tel') }}</a>
                            </div>
                            <div>{{ $t('footer.time') }}</div>
                        </div>
                    </div>
                    <div class="col-md-6 item">
                        <h6>
                            {{ $t('footer.legal') }}
                        </h6>
                        <div class="customer sub-body">
                            <div>
                                <a href="/term/OLACRED_TERMS_AND_CONDITIONS">
                                    {{ $t('footer.terms') }}
                                </a>
                            </div>
                            <div>
                                <a href="/term/OLACRED_PRIVACY_POLICY">
                                    {{ $t('footer.privacy') }}
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 item">
                        <h6>
                            {{ $t('footer.about') }}
                        </h6>
                        <div class="customer sub-body">
                            <div>
                                <router-link to="/faq">
                                    {{ $t('faq.title') }}
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="container copy-right-wrapper">
                <div class="copy-right sub-body">
                    {{ $t('footer.copyRight', [new Date().getFullYear()]) }}
                </div>
                <div
                    class="download"
                    @click="download"
                >
                    <img src="./img/foot-logo.png">
                    <div class="btn-text">
                        {{ $t('footer.btnText') }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script type="text/babel">
import {downloadUrl} from 'olacred/common/constant/config';

export default {
    name: 'CkFooter',
    methods: {
        download() {
            window.open(downloadUrl);
        }
    }
};

</script>

<style lang="scss" rel="stylesheet/scss" scoped>
@import "~olacred/common/style/olacred";

.ck-footer {
    position: relative;
    background-color: #2d2d38;
    z-index: 5;
    color: #fff;

    .sub-body {
        color: #c0c0c0;
    }

    @media screen and (max-width: $screen-md) {
        padding: 0 50px;
    }

    h6 {
        margin-bottom: 15px;
        color: #fff;
    }

    button {
        cursor: pointer;
    }

    .item {
        padding: 0;
        margin-right: 125px;
        max-width: 294px;

        &:last-child {
            margin-right: 0;
        }
    }

    .contact-info {
        padding: 40px 0 70px;

        @media screen and (max-width: $screen-md) {
            padding: 30px 0 60px;
        }

        .row {
            padding: 10px 0 48px;
            border-bottom: 1px solid #d8d8d8;
        }
    }

    @media screen and (max-width: $screen-md) {
        h6 {
            margin-top: 10px;
        }
    }

    .customer {
        div {
            margin-top: 10px;
        }

        @media screen and (max-width: $screen-sm) {
            margin-top: 10px;
            font-size: 12px;
            line-height: 25px;

            div {
                margin-top: 0;
            }
        }

        a {
            color: #c0c0c0;

            &:hover {
                color: #fff !important;
            }
        }
    }

    .copy-right-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 32px 0 0;
        color: #c0c0c0;
        text-align: center;

        @media screen and (max-width: $screen-md) {
            display: flex;
            flex-direction: column;
            padding: 10px 0 0;
        }

        .download {
            width: 240px;
            height: 60px;
            background: #fff;
            border-radius: 36px;
            display: flex;
            align-items: center;
            padding: 0 40px;
            cursor: pointer;

            img {
                width: 36px;
                height: 36px;
                margin-right: 14px;
            }

            .btn-text {
                font-size: 14px;
                font-family: Helvetica-Bold, Helvetica;
                font-weight: bold;
                color: #2d2d38;
                line-height: 17px;
                text-align: left;
            }

            @media screen and (max-width: $screen-md) {
                margin-top: 20px;
                width: 180px;
                height: 45px;
                padding: 0 25px;

                img {
                    width: 26px;
                    height: 26px;
                    margin-right: 10px;
                }

                .btn-text {
                    font-size: 12px;
                }
            }
        }
    }
}

</style>
