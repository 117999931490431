/**
 * @Author: chengyuzhang
 * @Date: 2020-04-13 16:38:27.247
 * @Last Modified by: ruiwang
 * @Last Modified time: 2023-04-07 16:04:05
 */

import Vue from 'ssr-common/vue';

import App from './app/app';
import {createI18n} from './common/i18n';
import {createRouter} from './common/router';
import {createStore} from './common/store';

export const createApp = context => {
    const i18n = createI18n(context);
    const router = createRouter(context);
    const store = createStore(context);

    const app = new Vue({
        i18n,
        router,
        store,
        render: h => h(App) // eslint-disable-line id-length
    });

    return {app, router, store};
};
