/*
 * @Author: qxy
 * @Date: 2020-03-27 14:12:13
 * @Last Modified by: zhaoyang
 * @Last Modified time: 2021-12-09 12:37:14
 */

import clickRipple from 'easycash/common/directive/click-ripple';
import scrollBar from 'easycash/common/directive/scroll-bar';

export default {
    install(Vue) {
        Vue.directive('scroll-bar', scrollBar);
        Vue.directive('click-ripple', clickRipple);
    }
};
