/*
 * @Author: xiaodongyu
 * @Date 2019-04-09 15:10:59
 * @Last Modified by: ruiwang
 * @Last Modified time: 2023-03-29 15:55:41
 */

import AntdIcon from '@ant-design/icons-vue';
import QuestionCircleFill from '@ant-design/icons/lib/fill/QuestionCircleFill';
import SoundFill from '@ant-design/icons/lib/fill/SoundFill';
import CloseCircleOutlined from '@ant-design/icons/lib/outline/CloseCircleOutline';
import CloseOutline from '@ant-design/icons/lib/outline/CloseOutline';
import DownOutline from '@ant-design/icons/lib/outline/DownOutline';
import LeftOutline from '@ant-design/icons/lib/outline/LeftOutline';
import RightOutline from '@ant-design/icons/lib/outline/RightOutline';

import AboutUsOutline from './custom/outline/about-us';
import AdjustmentOutline from './custom/outline/adjustment';
import ArrowBackOutline from './custom/outline/arrow-back';
import ArrowRightOutline from './custom/outline/arrow-right';
import CardFourOutline from './custom/outline/card-four';
import CardOneOutline from './custom/outline/card-one';
import CardThreeOutline from './custom/outline/card-three';
import CardTwoOutline from './custom/outline/card-two';
import CollateralOutline from './custom/outline/collateral';
import FastOutline from './custom/outline/fast';
import FeeOutline from './custom/outline/fee';
import HidePassword from './custom/outline/hide-password';
import IconArrowOutline from './custom/outline/icon-arrow';
import IconCheckedOutline from './custom/outline/icon-checked';
import LogoEasycashOutline from './custom/outline/logo-easycash';
import LogoWurangOutline from './custom/outline/logo-wurang';
import QuoteOutline from './custom/outline/quote';
import SecurityOutline from './custom/outline/security';
import ServiceOutline from './custom/outline/service';
import ShowPassword from './custom/outline/show-password';
import TelephoneOutline from './custom/outline/telephone';

AntdIcon.add(
    SoundFill,
    QuestionCircleFill,
    RightOutline,
    DownOutline,
    TelephoneOutline,
    ServiceOutline,
    AboutUsOutline,
    QuoteOutline,
    CardOneOutline,
    CardTwoOutline,
    CardThreeOutline,
    CardFourOutline,
    CollateralOutline,
    FastOutline,
    FeeOutline,
    SecurityOutline,
    AdjustmentOutline,
    ArrowRightOutline,
    ArrowBackOutline,
    LogoEasycashOutline,
    LogoWurangOutline,
    IconCheckedOutline,
    IconArrowOutline,
    LeftOutline,
    HidePassword,
    ShowPassword,
    CloseOutline,
    CloseCircleOutlined
);

export default AntdIcon;
