import { isIOS } from 'ssr-common/util/webview/ua';
var initBar = function (_a) {
    var color = _a.color, right = _a.right, width = _a.width;
    var barEl = document.createElement('div');
    var props = { color: color, right: right, width: width, borderRadius: width };
    barEl.classList.add('scroll-bar');
    Object.keys(props).forEach(function (prop) {
        if (props[prop])
            barEl.style[prop] = props[prop];
    });
    return barEl;
};
var getTop = function (el) { return el.getBoundingClientRect().top; };
var scrollHandler = function (scrollEl, barEl, paddingTop) {
    barEl.style.opacity = '1';
    var scrollTop = scrollEl.scrollTop, scrollHeight = scrollEl.scrollHeight, offsetHeight = scrollEl.offsetHeight;
    barEl.style.top = "".concat((scrollTop / scrollHeight) * offsetHeight + paddingTop, "px");
    barEl.style.height = "".concat((offsetHeight / scrollHeight) * offsetHeight, "px");
    setTimeout(function () {
        barEl.style.opacity = '0';
    }, 700);
};
var directive = {
    inserted: function (el, _a) {
        var value = _a.value;
        if (isIOS(navigator.userAgent))
            return;
        var options = value;
        var parentElement = el.parentElement;
        var barEl = initBar(options);
        var parentPaddingTop = getTop(el) - getTop(parentElement);
        parentElement.style.position = 'relative';
        parentElement.appendChild(barEl);
        setTimeout(function () {
            barEl.style.opacity = '0';
        }, 700);
        if (typeof window.MutationObserver === 'function') {
            var observer = new MutationObserver(function () {
                scrollHandler(el, barEl, parentPaddingTop);
            });
            observer.observe(el, {
                childList: true,
                attributes: true,
                attributeFilter: ['style'],
                attributeOldValue: true
            });
        }
        el.addEventListener('scroll', function () {
            scrollHandler(el, barEl, parentPaddingTop);
        });
    }
};
export default directive;
