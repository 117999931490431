/*
 * @Author: chengyuzhang
 * @Date: 2020-04-21 15:13:05
 * @Last Modified by: mikey.zhaopeng
 * @Last Modified time: 2020-04-21 15:29:04
 */

/* eslint-disable max-len */

export default {
    name: 'show-password',
    theme: 'outline',
    icon: {
        tag: 'svg',
        attrs: {
            viewBox: '0 0 1024 1024'
        },
        children: [
            {
                tag: 'path',
                attrs: {
                    d: 'M511.705799 213.262128c-176.458921 0-372.338147 99.651604-447.724091 298.483069 92.328825 201.619974 263.10328 298.484092 447.724091 298.484092 176.824241 0 381.703445-121.82358 447.724092-298.484092-66.859757-177.770799-261.573437-298.483068-447.724092-298.483069z m0 522.363022c-123.663484 0-223.862557-100.233865-223.862557-223.880977 0-123.645065 100.199073-223.879953 223.862557-223.879953 123.627668 0 223.862557 100.234889 223.862558 223.879953-0.001023 123.647111-100.235912 223.880977-223.862558 223.880977z'
                }
            },
            {
                tag: 'path',
                attrs: {
                    d: 'M641.927653 438.945147c-7.760754 5.747911-17.251919 9.254781-27.63643 9.254781-25.76071 0-46.638203-20.877493-46.638204-46.656622 0-9.154497 2.733251-17.634636 7.306407-24.839735-19.238157-9.026584-40.608883-14.21884-63.253627-14.21884-82.436524 0-149.242046 66.840314-149.242045 149.258419s66.805522 149.260465 149.242045 149.260466c82.418105 0 149.242046-66.842361 149.242046-149.260466 0-26.451442-6.959505-51.246151-19.020192-72.798003z'
                }
            }
        ]
    }
};
