export var genClickEvent = function (brand, tag, elementContent) { return ({
    tag: tag,
    businessEvent: "".concat(brand, "_").concat(tag),
    elementType: 'Button',
    eventType: 'C',
    elementContent: elementContent
}); };
export var genEnterEvent = function (brand, route, elementContent) { return ({
    tag: 'PageEnter',
    businessEvent: "".concat(brand, "_").concat(route.name, "_PageEnter"),
    elementType: 'Button',
    eventType: 'P',
    elementContent: elementContent
}); };
export var genEasycashClickEvent = function (tag, elementContent) { return genClickEvent('Easycash', tag, elementContent); };
export var genInvestajaClickEvent = function (tag, elementContent) { return genClickEvent('Investaja', tag, elementContent); };
