/*
 * @Author: ruiwang
 * @Date: 2019-12-18 15:21:16
 * @Last Modified by: jintingliu
 * @Last Modified time: 2021-02-02 14:19:00
 */

import VueI18n from 'vue-i18n';

import Vue from 'ssr-common/vue';

import EN_IN from './languages/en-in.json';
import ES from './languages/es.json';

Vue.use(VueI18n);

export const createI18n = context => new VueI18n({
    locale: context.acceptLanguage,
    fallbackLocale: 'es',
    messages: {
        en: EN_IN,
        es: ES
    },
    silentTranslationWarn: true
});
