/**
 * @Author: chengyuzhang
 * @Date: 2020-04-13 16:38:27.247
 * @Last Modified by: ruiwang
 * @Last Modified time: 2023-04-07 16:04:03
 */

export const rootTitle = 'Haab';

export const downloadKey = 'OLACRED_DOWNLOAD_URL';

export const downloadUrl = 'https://play.google.com/store/apps/details?id=com.mxolacred';

export const marketUrl = 'market://details?id=com.fintopia.mxolacred';
