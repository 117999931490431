/*
 * @Author: qxy
 * @Date: 2019-08-13 10:32:51
 * @Last Modified by: qxy
 * @Last Modified time: 2019-08-13 10:36:41
 */
/* eslint-disable max-len */

export default {
    name: 'adjustment',
    theme: 'outline',
    icon: {
        tag: 'svg',
        attrs: {
            viewBox: '0 0 21 21'
        },
        children: [
            {
                tag: 'path',
                attrs: {
                    d: 'M9.2 9.188a1.5 1.5 0 0 1 1.488-1.313h.937a1.5 1.5 0 0 1 1.488 1.313H20a1 1 0 0 1 1 1v.624a1 1 0 0 1-1 1h-6.887a1.5 1.5 0 0 1-1.488 1.313h-.938A1.5 1.5 0 0 1 9.2 11.812H1a1 1 0 0 1-1-1v-.624a1 1 0 0 1 1-1h8.2zm-5.25 7.874a1.5 1.5 0 0 1 1.487-1.312h.938a1.5 1.5 0 0 1 1.488 1.313H20a1 1 0 0 1 1 1v.625a1 1 0 0 1-1 1H7.863A1.5 1.5 0 0 1 6.375 21h-.938a1.5 1.5 0 0 1-1.488-1.313H1a1 1 0 0 1-1-1v-.625a1 1 0 0 1 1-1h2.95zm9.187-15.75A1.5 1.5 0 0 1 14.625 0h.938a1.5 1.5 0 0 1 1.488 1.313H20a1 1 0 0 1 1 1v.624a1 1 0 0 1-1 1h-2.95a1.5 1.5 0 0 1-1.488 1.313h-.937a1.5 1.5 0 0 1-1.488-1.313H1a1 1 0 0 1-1-1v-.624a1 1 0 0 1 1-1h12.137z'
                }
            }
        ]
    }
};
