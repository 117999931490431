var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "reach-show",
          rawName: "v-reach-show",
          value: "elegant-in",
          expression: "'elegant-in'",
        },
      ],
      staticClass: "ck-footer",
    },
    [
      _c("div", { staticClass: "contact-info" }, [
        _c("div", { staticClass: "container" }, [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-md-6 item" }, [
              _c("h6", [
                _vm._v(
                  "\n                        " +
                    _vm._s(_vm.$t("footer.customerService")) +
                    "\n                    "
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "customer sub-body" }, [
                _c("div", [
                  _c("a", { attrs: { href: "mailto:contacto@haab.mx" } }, [
                    _vm._v(_vm._s(_vm.$t("footer.email"))),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", [
                  _c("a", { attrs: { href: "tel:+5574545669" } }, [
                    _vm._v(_vm._s(_vm.$t("footer.tel"))),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", [_vm._v(_vm._s(_vm.$t("footer.time")))]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-md-6 item" }, [
              _c("h6", [
                _vm._v(
                  "\n                        " +
                    _vm._s(_vm.$t("footer.legal")) +
                    "\n                    "
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "customer sub-body" }, [
                _c("div", [
                  _c(
                    "a",
                    { attrs: { href: "/term/OLACRED_TERMS_AND_CONDITIONS" } },
                    [
                      _vm._v(
                        "\n                                " +
                          _vm._s(_vm.$t("footer.terms")) +
                          "\n                            "
                      ),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("div", [
                  _c("a", { attrs: { href: "/term/OLACRED_PRIVACY_POLICY" } }, [
                    _vm._v(
                      "\n                                " +
                        _vm._s(_vm.$t("footer.privacy")) +
                        "\n                            "
                    ),
                  ]),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-md-6 item" }, [
              _c("h6", [
                _vm._v(
                  "\n                        " +
                    _vm._s(_vm.$t("footer.about")) +
                    "\n                    "
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "customer sub-body" }, [
                _c(
                  "div",
                  [
                    _c("router-link", { attrs: { to: "/faq" } }, [
                      _vm._v(
                        "\n                                " +
                          _vm._s(_vm.$t("faq.title")) +
                          "\n                            "
                      ),
                    ]),
                  ],
                  1
                ),
              ]),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "container copy-right-wrapper" }, [
          _c("div", { staticClass: "copy-right sub-body" }, [
            _vm._v(
              "\n                " +
                _vm._s(_vm.$t("footer.copyRight", [new Date().getFullYear()])) +
                "\n            "
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "download", on: { click: _vm.download } }, [
            _c("img", { attrs: { src: require("./img/foot-logo.png") } }),
            _vm._v(" "),
            _c("div", { staticClass: "btn-text" }, [
              _vm._v(
                "\n                    " +
                  _vm._s(_vm.$t("footer.btnText")) +
                  "\n                "
              ),
            ]),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }