/**
 * @Author: chengyuzhang
 * @Date: 2020-04-13 16:38:27.247
 * @Last Modified by: ruiwang
 * @Last Modified time: 2023-11-17 10:45:22
 */

import 'core-js/stable';
import 'regenerator-runtime/runtime';
import {rejectFailedStatus} from '@yqg/http';
import 'ssr-common/util/report';

import {initSentry} from '@shared/client/util/sentry';

import {setSentryUser} from 'ssr-common/util/sentry-user';
import {createClientEntry} from 'ssr-common/vue/ssr/entry-client';
import http from 'ssr-common/vue/vue-http';

import {rootTitle} from './common/constant/config';
import {createApp} from './main';

http.interceptors.response.use(rejectFailedStatus());

const {router} = createClientEntry({createApp, rootTitle});

initSentry({
    router,
    dsn: 'https://33adba01cc2018eafbb5c4f504ae77b7@sentry.fintopia.tech/75'
});

setSentryUser();

if (module.hot) {
    module.hot.accept();
}
