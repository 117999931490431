/**
 * @Author: chengyuzhang
 * @Date: 2020-04-13 16:38:27.247
 * @Last Modified by: wuhuiwang
 * @Last Modified time: 2024-05-Th 04:19:19
 */

export default [
    {
        path: '/',
        name: 'Index',
        component: () => import('olacred/app/index'),
        redirect: {path: '/borrow'},
        children: [
            {
                path: 'borrow',
                name: 'Borrow',
                component: () => import('olacred/app/index/borrow')
            },
            {
                path: 'faq',
                name: 'Faq',
                component: () => import('olacred/app/index/faq')
            },
            {
                path: 'term/:key',
                name: 'term',
                component: () => import('olacred/app/index/term')
            },
            {
                path: 'about-us',
                name: 'AboutUs',
                component: () => import('olacred/app/index/about-us')
            }
        ]
    },
    {
        path: '/webview',
        component: () => import('olacred/app/webview'),
        children: [
            {
                path: 'help-center',
                name: 'helpCenter',
                component: () => import('olacred/app/webview/help-center'),
                redirect: {path: '/webview/help-center/loan'},
                children: [
                    {
                        name: 'question',
                        path: ':type',
                        component: () => import('easycash/app/webview/help-center/question')
                    }
                ]
            },
            {
                path: 'home',
                name: 'home',
                component: () => import('olacred/app/webview/home')
            },
            {
                path: 'about-us',
                name: 'webviewAboutUs',
                component: () => import('olacred/app/index/about-us')
            },
            {
                path: 'agreement',
                name: 'agreement',
                component: () => import('easycash/app/webview/agreement')
            },
            {
                path: 'agreement/:key',
                name: 'agreementKey',
                component: () => import('easycash/app/webview/agreement')
            },
            {
                path: 'static-agreement/:key',
                name: 'staticAgreement',
                component: () => import('easycash/app/webview/static-agreement')
            },
            {
                path: 'static-text/:key',
                name: 'staticText',
                component: () => import('easycash/app/webview/static-text')
            },
            {
                path: 'message',
                name: 'Message',
                component: () => import('olacred/app/webview/message-center/message')
            },
            {
                path: 'message/:id',
                name: 'MessageDetail',
                component: () => import('olacred/app/webview/message-center/message-detail')
            },
            {
                path: 'spider/operator',
                name: 'operator',
                component: () => import('olacred/app/webview/spider/operator'),
                meta: {
                    titleKey: 'webview.title.operator'
                }
            },
            {
                path: 'reloan-accounting',
                name: 'ReloanAccounting',
                component: () => import('easycash/app/webview/reloan-accounting')
            },
            {
                path: 'credit-risk-score',
                name: 'creditRiskScore',
                component: () => import('olacred/app/webview/credit-risk-score')
            },
            {
                path: 'repay/:method',
                name: 'repay',
                component: () => import('olacred/app/webview/repay')
            },
            {
                path: 'activity/lottery/:activityId',
                name: 'ActivityLottery',
                component: () => import('olacred/app/webview/activity/lottery')
            },
            {
                path: 'activity/record/:activityId',
                name: 'ActivityRecord',
                component: () => import('olacred/app/webview/activity/record')
            },
            {
                path: 'questionnaire',
                name: 'questionnaire',
                component: () => import('olacred/app/webview/questionnaire'),
                meta: {
                    noEdit: true
                }
            },
            {
                path: 'delete-account',
                name: 'deleteAccount',
                component: () => import('olacred/app/webview/unsubscribe/delete-account')
            },
            {
                path: 'unsubscribe',
                name: 'unsubscribe',
                component: () => import('olacred/app/webview/unsubscribe')
            },
            {
                path: 'unsubscribe/reason',
                name: 'unsubscribeReason',
                component: () => import('olacred/app/webview/unsubscribe/reason')
            },
            {
                path: 'unsubscribe/otp',
                name: 'unsubscribeOtp',
                component: () => import('olacred/app/webview/unsubscribe/otp')
            },
            {
                path: 'unsubscribe/confirm',
                name: 'unsubscribeConfirm',
                component: () => import('olacred/app/webview/unsubscribe/confirm')
            }
        ]
    },
    {
        path: '*',
        component: () => import('olacred/app/not-found')
    }
];
