/*
 * @Author: qxy
 * @Date: 2019-08-12 19:30:23
 * @Last Modified by: qxy
 * @Last Modified time: 2019-08-12 19:40:25
 */
/* eslint-disable max-len */

export default {
    name: 'security',
    theme: 'outline',
    icon: {
        tag: 'svg',
        attrs: {
            viewBox: '0 0 114 80'
        },
        children: [
            {
                tag: 'path',
                attrs: {
                    d: 'M31.999 50.412v-18.84h6.159v2.651h-3.509v13.54h3.509v2.65h-6.159zm36.16-16.19v-2.65h6.157v18.84h-6.158v-2.65h3.508v-13.54h-3.508zm-.326 32.374v-2.208h2.65v2.208h-2.65zM53.158 47.987a7.003 7.003 0 0 0 6.995-6.995 7.002 7.002 0 0 0-6.995-6.995 7.002 7.002 0 0 0-6.995 6.995 7.003 7.003 0 0 0 6.995 6.995zm0-16.64c5.318 0 9.646 4.327 9.646 9.645s-4.328 9.645-9.646 9.645c-5.318 0-9.646-4.327-9.646-9.645s4.328-9.646 9.646-9.646zM31.991 67.094a2.344 2.344 0 0 0 2.342-2.341 2.345 2.345 0 0 0-2.342-2.342 2.344 2.344 0 0 0-2.34 2.342 2.343 2.343 0 0 0 2.34 2.34zm0-7.333c2.291 0 4.206 1.56 4.791 3.667h8.376v2.65h-8.376c-.585 2.107-2.5 3.666-4.79 3.666A4.997 4.997 0 0 1 27 64.754a4.997 4.997 0 0 1 4.991-4.992zm9.5-43.872a2.343 2.343 0 0 0-2.34 2.34 2.344 2.344 0 0 0 2.34 2.343 2.345 2.345 0 0 0 2.342-2.342 2.344 2.344 0 0 0-2.342-2.341zm0 7.333c-2.29 0-4.205-1.56-4.79-3.667h-8.376v-2.65h8.376c.585-2.107 2.5-3.666 4.79-3.666a4.997 4.997 0 0 1 4.992 4.99 4.997 4.997 0 0 1-4.992 4.993zm9.504 17.304c.697 0 .733-1.25 0-1.258-.302-.007-.618 0-.92 0v1.258h.92zm0-2.408c1.977.008 2.336 2.387 1.063 3.25l.985 1.595v.186h-1.437l-.863-1.48h-.668v1.48H48.79v-5.03h2.206zm5.028 2.588c.704 0 .747-1.43 0-1.437-.303-.007-.626 0-.92 0v1.437h.92zm.007-2.588c2.408.008 2.408 3.745 0 3.745h-.927v1.286h-1.286v-5.03h2.214zm25.177 30.215a2.629 2.629 0 0 0 2.626-2.626v-3.057h-29.35v3.057a2.629 2.629 0 0 0 2.626 2.626h24.098zm-1.891-12.922H54.483v4.589h29.35V19.765h-29.35v6.807h24.833v28.84zm-49.666-2.65h47.016V29.223H29.65v23.539zM57.109 13.65a2.629 2.629 0 0 0-2.626 2.626v.838h29.35v-.838a2.629 2.629 0 0 0-2.626-2.626H57.109zM81.207 11a5.282 5.282 0 0 1 5.276 5.276v49.432a5.282 5.282 0 0 1-5.276 5.276H57.109a5.282 5.282 0 0 1-5.276-5.276V55.412H27v-28.84h24.833V16.276A5.282 5.282 0 0 1 57.109 11h24.098z'
                }
            }
        ]
    }
};
