/*
 * @Author: qxy
 * @Date: 2019-08-12 19:30:23
 * @Last Modified by: qxy
 * @Last Modified time: 2019-08-12 19:34:07
 */
/* eslint-disable max-len */

export default {
    name: 'collateral',
    theme: 'outline',
    icon: {
        tag: 'svg',
        attrs: {
            viewBox: '0 0 114 80'
        },
        children: [
            {
                tag: 'path',
                attrs: {
                    d: 'M21.872 18.744H19v-2.873h2.872v2.872h2.872v2.872h-2.872v-2.871zm0-2.873V13h2.872v2.871h-2.872zm2.872 2.872v-2.872h2.871v2.872h-2.871zm59.233 43.753v-2.871h2.872v2.871h-2.872zm2.872 2.872v-2.872h2.871v2.872h-2.871zm-2.872 2.872v-2.872h2.872v2.872h-2.872zm-2.872-2.872v-2.872h2.872v2.872h-2.872zm-19.146-24.46H51.276v5.99h4.017v-2.996h2.65v2.996h4.016v-5.99zm-7.883-5.569v2.918h5.083V35.34a2.167 2.167 0 0 0-2.165-2.165h-.753c-1.194 0-2.165.97-2.165 2.165zm2.918-4.816a4.822 4.822 0 0 1 4.816 4.816v2.918h2.799V49.55H48.626V38.257h2.8V35.34a4.82 4.82 0 0 1 4.815-4.816h.753zM79.854 47.1l2.563.668c-3.05 11.732-13.66 19.927-25.799 19.927h-30.63v-2.651h7.754l-3.465-3.464h-4.289v-2.65h5.386l6.114 6.114h7.582c-6.895-3.316-12.236-9.515-14.254-17.283l-.437-1.68 11.945.15.34.79a15.173 15.173 0 0 0 13.954 9.199c8.304 0 14.965-6.51 15.164-14.818l.03-1.294h15.435v2.65H74.355c-.859 9.134-8.417 16.113-17.737 16.113a17.823 17.823 0 0 1-16.027-10.011l-6.708-.084c3.28 9.662 12.39 16.268 22.735 16.268 10.931 0 20.486-7.38 23.235-17.944zm-23.236-30.07c-10.933 0-20.487 7.38-23.236 17.945l-2.565-.668c3.052-11.732 13.662-19.927 25.8-19.927h30.63v2.65h-7.754l3.465 3.464h4.289v2.65H81.86l-6.113-6.114h-7.592c6.859 3.3 12.189 9.452 14.232 17.158l.44 1.665H70.916l-.345-.802a15.175 15.175 0 0 0-13.953-9.198c-8.306 0-14.967 6.51-15.164 14.82l-.03 1.294H25.988v-2.65H38.88c.858-9.136 8.416-16.114 17.737-16.114a17.82 17.82 0 0 1 16.02 10h6.68c-3.306-9.605-12.404-16.173-22.7-16.173zm33.707 0v-2.65h2.813v2.65h-2.813zm0 6.115v-2.65h2.813v2.65h-2.813zm-6.112 12.708v-2.65h2.813v2.65h-2.813zm6.112 6.905v-2.65h2.813v2.65h-2.813zM20.097 67.693v-2.65h2.813v2.65h-2.813zm0-6.114v-2.65h2.813v2.65h-2.813zm6.111-12.71v-2.65h2.813v2.65h-2.813zm-6.111-6.903v-2.65h2.813v2.65h-2.813z'
                }
            }
        ]
    }
};
