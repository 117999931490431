/*
 * @Author: chengyuzhang
 * @Date: 2020-09-28 11:13:59
 * @Last Modified by: chengyuzhang
 * @Last Modified time: 2021-09-28 12:24:07
 */

export default {
    namespaced: true,

    state: () => ({
        primaryColor: '#ff734b',
        brand: 'olacred'
    })
};
